.lightbox {
	position: fixed;
	display: none;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(10px);
}
.lightbox.open {
	display: flex;
	justify-content: center;
	align-items: center;
}

.close-lightbox {
	cursor: pointer;
	position: absolute;
	right: 3rem;
	top: 3rem;
	padding: 0.5rem 0;
	margin-bottom: 1.5rem;
}

.lightbox-content{
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	transition: all 0.5s ease;
}
.grid-slide {
  display: none;
	padding: 1rem;
}
.grid-slide.active{
  display: block;
}

.grid-slide img{
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.3);
}