@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;600&display=swap);
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
  font-family: 'Poppins', Helvetica, sans-serif;
  font-weight: 300;
	color: rgb(20, 20, 20);
	height: 100vh;
	background: rgb(251, 255, 253);
	background: radial-gradient(
		circle,
		rgb(255, 255, 255) 50%,
		rgb(240, 242, 241) 75%,
		rgb(214, 214, 214) 100%
  );
}

a {
	text-decoration: none;
	color: rgb(20, 20, 20);
	font-weight: 600;
}

a:hover {
	color: rgb(0, 197, 181);
}

button {
	border: none;
	border-radius: 5px;
	padding: 0.5rem 1rem;
	margin: 0 10px;
	cursor: pointer;
	transition: all 0.5s ease;
}

button:hover {
	background: rgb(0, 197, 181);
}

button:focus {
	outline: none;
}

img {
	max-width: 100%;
}

header {
	display: flex;
	align-items: center;
	padding: 1rem 2.5rem;
	min-height: 4rem;
	height: 7%;
}

#sm-menu {
  display: none;
}

header h1 {
	font-size: 1.2rem;
}

nav {
	padding: 0.5rem 2rem;
	height: 4%;
}

nav ul {
	list-style: none;	
}

nav li {
	display: inline;
	margin: 0 0.5rem;
	padding: 0.5rem;
	cursor: pointer;
}

nav li:first-child {
	margin-left: 0;
}

nav li:hover,
nav li.active {
	color: #5e60ce;
}

nav li.about:hover{
  color: rgb(0, 197, 181);
}

#main {
	margin: auto;
	width: calc(100vw - 15%);
	min-height: 82vh;
}

#gallery {
	display: flex;
	justify-content: center;
	padding: 2rem;
	height: 80vh;
	min-height: 500px;
}

.gwrapper {
	display: flex;
	width: 100vh;
	justify-content: center;
	align-items: center;
	transition: all 0.5s ease;
}

.g-prev,
.g-next {
	font-weight: 700;
	cursor: pointer;
	padding: 1rem;
}

.g-prev:hover,
.g-next:hover {
	color: #5e60ce;
}

.g-prev.inactive,
.g-next.inactive {
	color: #666;
	pointer-events: none;
}

.home-gallery {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.gallery-image {
	position: absolute;
	opacity: 0;

	transition: all 0.5s ease;
}

.gallery-image.active {
	opacity: 1;
}

.gallery-image img {
	width: 100%;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.changing-gallery {
	animation: gallery-switch 0.7s ease;
}



#tiles {
	margin: 3rem 0;
}

.image-tile {
	cursor: pointer;
}

.row {
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
	justify-content: center;
}

.column {
	flex: 30% 1;
	max-width: 30%;
	padding: 0 4px;
}

.column img {
	margin-top: 8px;
	vertical-align: middle;
	width: 100%;
}




.view-options {
	width: 1.3rem;
	float: left;
	margin: 1.5rem 2.5rem;
	cursor: pointer;
}



@keyframes gallery-switch {
	0% {
		opacity: 0;
		-webkit-filter: invert(100%) brightness(60%);
		        filter: invert(100%) brightness(60%);
	}
	50% {
		opacity: 0.8;
		-webkit-filter: invert(100%) brightness(60%);
		        filter: invert(100%) brightness(60%);
		transform: scale(0.99);
	}
	100% {
		opacity: 1;
		-webkit-filter: invert(0%) brightness(100%);
		        filter: invert(0%) brightness(100%);
		transform: scale(1);
	}
}

footer {
	width: 100%;
	height: 7%;
	padding: 1rem 2rem;
}

footer .social-links {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

footer .social-links a {
	margin: 0 1rem;
}

@media (max-width: 768px) {
	header {
		padding: 1rem;
	}

	#sm-menu {
		display: block;
		background: transparent;
		margin: 0 1rem 0 0;
	}
	
	nav {
		position: fixed;
		top: 50px;
		height: auto;
		z-index: 99;
    display: none;
    border-radius: 0 0 7px 7px ;
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.8)
		);
		-webkit-backdrop-filter: blur(15px);
		        backdrop-filter: blur(15px);
	}
	nav.open {
    display: block;
		animation: open-menu 0.3s ease;
	}
	nav.closing {
		animation: close-menu 0.3s ease;
	}

	@keyframes open-menu {
		0% {
      transform: translateX(-200%);
      opacity: 0;
		}
		100% {
      transform: translateX(0%);
      opacity: 1;
		}
	}
	@keyframes close-menu {
		0% {
      transform: translateX(0%);
      opacity: 1;
		}
		100% {
      transform: translateX(-200%);
      opacity: 0;
		}
	}

	#main-menu li {
		display: block;
		margin: 0.5rem 0;
	}

	#main {
		width: 100%;
		min-height: auto;
	}

	#gallery {
		padding: .2rem;
	}

  footer{
    position: absolute;
    bottom: 0;
  }
	footer .social-links {
    font-size: .9rem;
		justify-content: center;
	}

	.view-options{
		float: none;
		margin: 0.5rem 2rem;
	}
}

.lightbox{position:fixed;display:none;top:0;left:0;right:0;height:100%;background:rgba(255,255,255,.8);-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px)}.lightbox.open{display:flex;justify-content:center;align-items:center}.close-lightbox{cursor:pointer;position:absolute;right:3rem;top:3rem;padding:.5rem 0;margin-bottom:1.5rem}.lightbox-content{display:flex;width:100%;justify-content:center;align-items:center;transition:all .5s ease}.grid-slide{display:none;padding:1rem}.grid-slide.active{display:block}.grid-slide img{box-shadow:0 4px 25px rgba(0,0,0,.3)}
